import React from "react";
import Image from "next/image";
import Link from "next/link";

const AboutUsContent = () => {
  return (
    <React.Fragment>
      <div className="about-area-two ptb-70 bg-fef8ef">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-12">
              <div className="about-content">
                <span className="sub-title">As a Student</span>
                <h2>Shop, learn, and connect in your personalized education hub!</h2>
                <p>
                  Our platform provides premium curated content from professionals around the web. Engage in dynamic,
                  live sessions that promote active participation, or delve into our archive of pre-recorded materials
                  and accessible instructors whenever you choose.
                </p>
                <ul className="features-list">
                  <li>
                    <span>
                      <i className="fi fi-rr-users-alt"></i> Join Live Classes
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fi fi-rr-folder-upload"></i> Share Your Projects
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fi fi-rr-feedback-alt"></i> Book Coaching Sessions
                    </span>
                  </li>
                  <li>
                    <span>
                      <i className="fi fi-rr-video-duration"></i> Watch Video Courses
                    </span>
                  </li>
                </ul>
                <Link legacyBehavior href="/authentication">
                  <a className="default-btn">
                    Join for Free <span></span>
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-md-12">
              <div className="about-student-box">
                <div className="image">
                  <Image width={750} height={500} src="/images/courses16.jpeg" alt="Students Learning" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape3">
          <Image width={150} height={150} src="/images/shape3.png" alt="image" />
        </div>
        <div className="shape4">
          <Image width={62} height={62} src="/images/shape4.png" alt="image" />
        </div>
        <div className="shape2">
          <Image width={206} height={258} src="/images/shape2.png" alt="image" />
        </div>
      </div>
    </React.Fragment>
  );
};

export default AboutUsContent;
