import React from "react";
import Link from "next/link";
import MuxPlayer from "@mux/mux-player-react";

const AsInstructor = () => {
  return (
    <div className="about-area bg-fef8ef ptb-70 pb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <MuxPlayer
              streamType="on-demand"
              playbackId={"XaB100QnCdYJfmwfZvHEJE202ZaPeQp1eQqnAHr4ms00Fo"}
              preload="auto"
              style={{ aspectRatio: 16 / 9 }}
              className="mux-player no-controls"
              autoPlay={true}
              muted
              loop
            />
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <span className="sub-title">As an Instructor</span>
              <h2>Market your educational services in a variety of formats!</h2>
              <p>
                Combine pre-recorded videos, coaching, and live classes to create your personalized educational products
                and market them to learners. Utilize our built-in delivery services or seamlessly integrate our checkout
                process with your website.
              </p>

              <ul className="features-list">
                <li>
                  <span>
                    <i className="fi fi-rr-compliance-document"></i> Full Control of Your Content
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fi fi-rr-tags"></i> Set Your Prices
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fi fi-rr-tax-alt"></i> Commission-based Earnings
                  </span>
                </li>
                <li>
                  <span>
                    <i className="fi fi-rr-head-side-brain"></i> Promote Your Expertise
                  </span>
                </li>
              </ul>

              <Link legacyBehavior href="/become-a-teacher">
                <a className="default-btn">
                  Apply as Instructor <span></span>
                </a>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div className="shape1">
        <img src="/images/shape1.png" alt="image" />
      </div>
      <div className="shape2">
        <img src="/images/shape2.png" alt="image" />
      </div>
      <div className="shape3">
        <img src="/images/shape3.png" alt="image" />
      </div>
      <div className="shape4">
        <img src="/images/shape4.png" alt="image" />
      </div>
    </div>
  );
};

export default AsInstructor;
