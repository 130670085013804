import React from "react";
import MuxPlayer from "@mux/mux-player-react";

const MainBanner = () => {
  return (
    <div className="main-banner ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="main-banner-content">
              <h1>
                An{" "}
                <span
                  style={{
                    background: "linear-gradient(45deg, #d533d6, #ff6a00)",
                    WebkitBackgroundClip: "text",
                    WebkitTextFillColor: "transparent",
                  }}
                >
                  Open Learning
                </span>{" "}
                Marketplace
              </h1>

              <p>
                Learn at a time and pace that suits you best—Coursepage offers more ways to access your educational
                content and receive personalized support, all without strict curricula or outdated materials.
              </p>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <MuxPlayer
              streamType="on-demand"
              playbackId={"1vroBVGnvXt2bSum2afbX6LcX97rMsoJ025vaOwdwD00k"}
              preload="auto"
              style={{ aspectRatio: 16 / 9 }}
              className="mux-player no-controls"
              autoPlay={true}
              muted
              loop
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
