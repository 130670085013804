import React from "react";
import Link from "next/link";
import removeMd from "remove-markdown";
import shortenText from "@/lib/shortenText";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import EventIcon from "@mui/icons-material/Event";
import Chip from "@mui/material/Chip";
import { format, parseISO } from "date-fns";
import CardGiftcardRoundedIcon from "@mui/icons-material/CardGiftcardRounded";

const CustomPausedOverlay = ({ imageSrc, text }) => (
  <div style={styles.pausedOverlay}>
    <img src={imageSrc} alt="" style={styles.hoverVideo} />
    <span style={styles.overlayText}>{text}</span>
  </div>
);

const LiveCourseCard = ({
  id,
  bundleId,
  bundleLength,
  title,
  price,
  overview,
  profilePhoto,
  user,
  category,
  coursePreviewThumb,
  scheduledStartTime,
  guestName,
}) => {
  const getChipLabel = () => {
    const formattedTime = format(parseISO(scheduledStartTime), "Pp");
    return bundleId ? `${formattedTime} + ${bundleLength - 1}` : formattedTime;
  };

  const coursePreviewOverlay = (
    <CustomPausedOverlay
      imageSrc={profilePhoto}
      text={
        <Chip
          variant="outlined"
          sx={styles.chipStyle}
          label={
            <div style={styles.chipLabelStyle}>
              <EventIcon sx={styles.eventIconStyle} fontSize="small" />
              {getChipLabel()}
            </div>
          }
        />
      }
    />
  );

  const linkHref = bundleId ? "/bundle/[id]" : "/courses/[id]";
  const linkAs = bundleId ? `/bundle/${bundleId}` : `/courses/${id}`;

  const CategoryIcon = bundleId ? CardGiftcardRoundedIcon : CategoryOutlinedIcon;

  return (
    <Link legacyBehavior href={linkHref} as={linkAs}>
      <a style={styles.anchorStyle}>
        <div className="single-courses-box">
          <div className="courses-image">
            <div className="d-block image">{coursePreviewOverlay}</div>
          </div>

          <div className="courses-content">
            <div className="course-author d-flex align-items-center">
              <img
                src={user.profilePhoto ? user.profilePhoto : "/images/advisor/advisor3.svg"}
                className="rounded-circle"
                alt={user.name}
              />
              <span className="teacher-name">{user.name}</span>
              {price ? <strong className="price-box">${Math.floor(price)}</strong> : ""}
            </div>
            <h3>{title}</h3>
            {guestName && (
              <span className="sub-title">
                <i className="fi fi-sr-review"></i>
                {guestName}
              </span>
            )}
            <p>{shortenText(coursePreviewThumb || removeMd(overview), 200)}</p>
            <ul className="courses-box-footer d-flex justify-content-between">
              <li>
                <CategoryIcon sx={styles.categoryIconStyle} fontSize="small" htmlColor="#b163f1" /> {category}
              </li>
              <li>
                <VerifiedRoundedIcon sx={styles.verifiedIconStyle} htmlColor="#27ab5f" /> Live
              </li>
            </ul>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default LiveCourseCard;

const styles = {
  hoverVideo: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    display: "block",
  },
  pausedOverlay: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
  overlayText: {
    position: "absolute",
    top: 0,
    right: 0,
    margin: "10px",
    color: "#FFF",
  },
  chipStyle: {
    fontSize: "14px",
    backgroundColor: "white",
  },
  chipLabelStyle: {
    marginTop: "2px",
    display: "flex",
    fontWeight: "700",
  },
  eventIconStyle: {
    marginRight: "3px",
  },
  categoryIconStyle: {
    marginBottom: "3px",
  },
  verifiedIconStyle: {
    marginBottom: "5px",
  },
  anchorStyle: { display: "block", textDecoration: "none", color: "inherit", fontWeight: "inherit" },
};
