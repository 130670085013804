import React from "react";
import MainBanner from "@/components/eLearningSchool/MainBanner";
import Features from "@/components/eLearningSchool/Features";
import AsInstructor from "@/components/eLearningSchool/AsInstructor";
import PopularCourses from "@/components/eLearningSchool/PopularCourses";
import Bundles from "@/components/eLearningSchool/Bundles";
import AboutUsContent from "@/components/About/AboutUsContent";
import axios from "axios";
import baseUrl from "@/utils/baseUrl";
import useSWR from "swr";
import Script from "next/script";

const fetcher = (args) => axios.get(...args).then((res) => res.data);

const structuredData = {
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Coursepage",
  url: "https://coursepage.com",
  potentialAction: {
    "@type": "SearchAction",
    target: {
      "@type": "EntryPoint",
      urlTemplate: "https://coursepage.com/search?q={search_term_string}",
    },
    "query-input": "required name=search_term_string",
  },
};

const Index = () => {
  const popularCourses = useSWR([`${baseUrl}/api/v1/courses/popular`], fetcher);
  const bundles = useSWR([`${baseUrl}/api/v1/courses/bundles`], fetcher);
  const coursesData = popularCourses.data ? popularCourses.data.courses : [];
  const bundlesData = bundles.data ? bundles.data.bundles : [];

  return (
    <div className="homepage">
      <Script
        strategy="beforeInteractive"
        id="site-name"
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredData) }}
      />
      <div style={styles.mainRow} className="row">
        <MainBanner />
      </div>
      <div style={styles.mainRow} className="row">
        <Features />
      </div>
      <div style={styles.mainRow} className="row">
        <PopularCourses courses={coursesData} />
      </div>
      <div style={styles.mainRow} className="row">
        <Bundles bundles={bundlesData} />
      </div>
      <div style={styles.mainRow} className="row">
        <AboutUsContent />
      </div>
      <div style={styles.mainRow} className="row">
        <AsInstructor />
      </div>
    </div>
  );
};

export default Index;

const styles = {
  mainRow: { width: "100%", margin: "0" },
};
