import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import Modal from "react-responsive-modal";
import CloseIcon from "@/components/Icons/CloseIcon";
import MuxPlayer from "@mux/mux-player-react";
import "react-responsive-modal/styles.css";

const Player = ({ url, open, toggleModal }) => {
  const [isSafari, setIsSafari] = useState(false);

  // Check for Safari on component mount
  useEffect(() => {
    const userAgent = typeof window !== "undefined" ? navigator.userAgent : "";
    setIsSafari(/^((?!chrome|android).)*safari/i.test(userAgent));
  }, []);

  const safariModalContent = (
    <div style={styles.safariVideoModal}>
      <div style={styles.safariOverlay}></div>
      <div style={styles.safariModalContainer}>
        <div style={styles.safariModalBody}>
          <button onClick={() => toggleModal()} style={styles.closeBtn}>
            <CloseIcon />
          </button>
          <MuxPlayer
            streamType="on-demand"
            style={styles.safariPlayerStyle}
            playbackId={url.match(/mux\.com\/(.*?)\.m3u8/)?.[1]}
            preload="metadata"
            className="mux-player"
            autoPlay={true}
            accentColor="#b163f1"
          />
        </div>
      </div>
    </div>
  );

  return isSafari && open ? (
    ReactDOM.createPortal(safariModalContent, document.body)
  ) : (
    <Modal
      open={open}
      blockScroll={true}
      onClose={toggleModal}
      styles={{ modal: styles.modalStyle }}
      closeIcon={<CloseIcon />}
      center
    >
      <MuxPlayer
        streamType="on-demand"
        style={styles.playerStyle}
        playbackId={url.match(/mux\.com\/(.*?)\.m3u8/)?.[1]}
        preload="metadata"
        className="mux-player"
        autoPlay={true}
        accentColor="#b163f1"
      />
    </Modal>
  );
};

export default Player;

const styles = {
  modalStyle: {
    padding: "40%",
    background: "none",
    overflow: "hidden",
    position: "relative",
    boxSizing: "border-box",
    boxShadow: "none",
  },
  playerStyle: { position: "absolute", top: "0", left: "0", aspectRatio: 16 / 9 },
  safariVideoModal: {
    position: "fixed",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: "1006",
    display: "block",
  },
  closeBtn: {
    position: "absolute",
    top: "2%",
    right: "2%",
    background: "rgba(0, 0, 0, 0)",
    border: "none",
    cursor: "pointer",
    zIndex: "1007",
  },
  safariPlayerStyle: {
    width: "800px",
    height: "auto",
    aspectRatio: 16 / 9,
    borderRadius: "5px",
  },
  safariOverlay: {
    background: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    zIndex: "-1",
  },
  safariModalContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    outline: "0",
    overflowX: "hidden",
    overflowY: "hidden",
    textAlign: "center",
  },
  safariModalBody: {
    maxWidth: "800px",
    width: "100%",
    background: "#ffffff",
    boxShadow: "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
    margin: "1.2rem",
    overflowY: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "5px",
  },
};
