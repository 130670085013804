import React, { useCallback } from "react";
import CourseCard from "../Courses/CourseCard";
import { Instagram } from "react-content-loader";
import useEmblaCarousel from "embla-carousel-react";

const PopularCourses = ({ courses }) => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ dragFree: true, slidesToScroll: 3 });
  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  return (
    <div className="courses-area ptb-70" style={styles.container}>
      <div className="container plr-0">
        <div className="carousel-section-container">
          <h2>Popular Courses</h2>
        </div>
        {courses?.length > 0 ? (
          <div className="embla">
            <div className="embla__viewport" ref={emblaRef}>
              <div className="embla__container">
                {courses.map((course) => (
                  <div key={course.id} className="embla__slide">
                    <CourseCard {...course} key={course.id} />
                  </div>
                ))}
              </div>
            </div>
            <button className="embla__prev" onClick={scrollPrev}>
              <i className="fi fi-sr-angle-small-left"></i>
            </button>
            <button className="embla__next" onClick={scrollNext}>
              <i className="fi fi-sr-angle-small-right"></i>
            </button>
          </div>
        ) : (
          <div>
            <div className="row">
              {new Array(3).fill(0).map((val, i) => (
                <div className="col-lg-4 col-md-6" key={val + i}>
                  <Instagram uniqueKey="content-loader-key" />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PopularCourses;

const styles = {
  container: { zIndex: "0" },
  pausedOverlay: {
    position: "relative",
    width: "100%",
    height: "100%",
  },
};
